<template>
  <mdb-side-nav-2 :key="key" v-model="show" :data="navigation" groups>
    <div slot="header" class="mt-3 grey-text">
      <img src="@/assets/images/logo_assetta.png" alt=""/>
      <h5 class="mt-4 ml-1">
        <strong>관리자</strong>
      </h5>
      <p class="ml-1">{{ $store.state.user_email }}</p>
      <hr class="mb-0"/>
    </div>
    <mdb-container
        fluid
        ref="main"
        slot="main"
        class="view d-flex flex-column grey-text"
    >
      <router-view></router-view>
    </mdb-container>

  </mdb-side-nav-2>

</template>

<script>
import {mdbContainer, mdbSideNav2} from "mdbvue";

export default {
  name: "Admin",
  components: {
    mdbContainer,
    mdbSideNav2,
  },
  data() {
    return {
      key: 0,
      show: true,
      header: false,
      navigation: [
        {
          data: [
            {
              name: "Welcome",
              icon: "info",
              to: "/admin/welcome"
            },
            {
              name: "멤버 관리",
              icon: "users",
              to: "/admin/member-manage"
            },
            {
              name: "협력사 관리",
              icon: "building",
              to: "/admin/partnerManage"
            },
            {
              name: "영상 관리",
              icon: "video",
              to: "/admin/youtubeManageList"
            }
          ]
        },
        {
          data: [
            {
              name: "공지사항 관리",
              icon: "bullhorn",
              to: "/admin/noticeManageList"
            },
            {
              name: "아이디어 접수 관리",
              icon: "brain",
              children: [
                {
                  name: "접수 활성 상태",
                  to: "/admin/ideaBoardOnOff"
                },
                {
                  name: "2024년도",
                  to: "/admin/ideaManageList/2024"
                },
                {
                  name: "2023년도",
                  to: "/admin/ideaManageList/2023"
                },
                {
                  name: "2022년도",
                  to: "/admin/ideaManageList/2022"
                },
                {
                  name: "2021년도",
                  to: "/admin/ideaManageList/2021"
                },
              ],
            },
            {
              name: "시제품 의뢰 관리",
              icon: "memory",
              to: "/admin/requestManageList"
            },
            {
              name: "기술 문의 관리",
              icon: "tools",
              to: "/admin/technicalManageList"
            },
          ]
        },
        {
          data: [
            {
              name: "계정 관리",
              icon: "user",
              to: "/admin/Account-manage"
            },
            // {
            //   name: "정책 관리",
            //   icon: "lock",
            //   to: "/admin/privacy-manage"
            // }
          ]
        },
        {
          data: [
            {
              name: "홈으로",
              icon: "sign-out-alt",
              to: '/'
            }
          ]
        }
      ],
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.view {
  min-height: 100vh;
  background-color: #eee;
}
</style>
